// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
import { Login, LoginOut } from '@/api/login/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tabs: [],
    roles: [],
    permissions: [],
    token: getToken(),
    userInfo: {}

  },

  mutations: {
    addTab(state, tab) {
      state.tabs.push(tab);
    },
    removeTab(state, tabName) {
      state.tabs = state.tabs.filter(tab => tab.name !== tabName);
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
  },
  actions: {
    // 获取用户信息
    // GetInfo({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     listUserRoleMenu().then(res => {
    //       console.log(res)
    //       // const user = res.user
    //       // const avatar = (user.avatar == "" || user.avatar == null) ? require() : process.env.VUE_APP_BASE_API + user.avatar;
    //       if (res.data && res.data.length > 0) { // 验证返回的roles是否是一个非空数组
    //         commit('SET_ROLES', res.data)
    //         commit('SET_PERMISSIONS', res.data)
    //         sessionStorage.setItem('menu', JSON.stringify(res.data))
    //       } else {
    //         commit('SET_ROLES', ['ROLE_DEFAULT'])
    //       }
    //       // commit('SET_NAME', user.userName)
    //       // commit('SET_AVATAR', avatar)
    //       resolve(res)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    Login({ commit }, userInfo) {
      const login = userInfo.login.trim()
      const password = userInfo.password
      // const code = userInfo.code
      // const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        Login({ login: login, password: password }).then(res => {
          if (res.state == '0') {
            setToken(res.data.token)
            commit('SET_USERINFO', res.data)
            commit('SET_TOKEN', res.data.token)
          }

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        LoginOut().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USERINFO', {})
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
});