import Cookies from 'js-cookie'

const TokenKey = 'AuthorizationToken'

export function getToken() {
  // let jwt = getQueryVariable('jwt')
  // if(jwt){
  //   setToken(jwt)
  //   return jwt
  // }
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
function getQueryVariable(variable) {
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return (false);
}
